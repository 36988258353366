$(document).on('turbolinks:load', function() {
  $(document).on('click', '.details-toggle', function(e) {
    var details = $(this).data('details');
    $('> i', this).toggleClass('fa-eye fa-eye-slash');
    $('.' + details).toggleClass('hidden');
    e.preventDefault();
    e.stopPropagation();
    return false;
  });
});
