document.addEventListener('turbolinks:load', function() {

  if ($('form.negotiation-form').length) {

    var checkAll = document.querySelector('.checkall')
    var discountTypeInputs = document.querySelectorAll('.negotiation-discount-type');
    var discountPercentage = $('#discount-percentage');
    var discountMoney = $('#discount-money');
    var discountType = 'percentage'; //Default value for Currency DiscountType
    var negotiationDueValue = $('#negotiation_due_value');
    var negotiationFinalValue = $('#negotiation_value');


    if (checkAll) {
      checkAll.addEventListener('click', function () {
        checkboxes = document.querySelectorAll('.debt-id-input-checkbox');
        Array.from(checkboxes).forEach(checkbox => {
          checkbox.checked = checkAll.checked;
          fillDueValue();
        });
      })
    }

    $(document).on('click', '.debt-id-input-checkbox', function(e) {
      fillDueValue();
    });

    discountMoney.prop('disabled', true);
    discountMoney.hide();

    discountPercentage.val('100%');
    Array.from(discountTypeInputs).forEach(discountTypeInput => {
      discountTypeInput.addEventListener('click', function(){
        discountType = discountTypeInput.value;
        if (discountType === 'currency') {
          discountMoney.val(negotiationDueValue.val());
          discountMoney.prop('disabled', false)
          discountMoney.show()
          discountPercentage.prop('disabled', true)
          discountPercentage.hide()
        } else if (discountType === 'percentage') {
          discountPercentage.val('100%');
          discountPercentage.prop('disabled', false)
          discountPercentage.show()
          discountMoney.prop('disabled', true)
          discountMoney.hide()
        }
        negotiationFinalValue.maskMoney('mask', 0, {
          'prefix': 'R$ ',
          'thousands': '.',
          'decimal': ',',
          'allowZero': true
        });
      });
    });

    $(document).on('focus', '#negotiation_value', function(e) {
      calcFinalValue();
    });

    discountPercentage.on('keyup', function(){
      var value = parseInt(discountPercentage.cleanVal());
      if (value > 100) {
        discountPercentage.val('100%');
      }
      calcFinalValue();
    });

    discountMoney.on('keyup', function() {
      var value = parseFloat(discountMoney.maskMoney('unmasked')[0]);
      var due = parseFloat(negotiationDueValue.maskMoney('unmasked')[0]);
      if (value > due) {
        discountMoney.val(negotiationDueValue.val());
      }
      calcFinalValue();
    });


    var fillDueValue = function() {
      value = 0
      $('.debt-id-input-checkbox:checked').each(function (e) {
        value = value + $(this).data('value');
      })

      negotiationDueValue.maskMoney('mask', value, {
        'prefix': 'R$ ',
        'thousands': '.',
        'decimal': ',',
        'allowZero': true
      });

      calcFinalValue();
    }

    var calcFinalValue = function() {
      var discount, due, final;
      due = negotiationDueValue.maskMoney('unmasked')[0];
      if (discountType === 'currency') {
        discount = discountMoney.maskMoney('unmasked')[0];
      } else if (discountType === 'percentage') {
        discount = due * discountPercentage.cleanVal() / 100;
      }

      final = due - discount;

      negotiationFinalValue.maskMoney('mask', final, {
        'prefix': 'R$ ',
        'thousands': '.',
        'decimal': ',',
        'allowZero': true
      });
    }
}
});
