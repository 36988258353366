$(document).on("turbolinks:load", function() {
  var active_tab, person_tabs;
  person_tabs = $('#personal-tabs');
  if (person_tabs.length) {
    active_tab = person_tabs.data('active');
    if (active_tab !== '') {
      $('#personal-tabs a[href="#' + active_tab + '"]').tab('show');
    }
    return $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
      return $('#active_tab').val(e.target.href.split('#')[1]);
    });
  }
});
