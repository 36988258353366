$(document).on("turbolinks:load", function() {
  if ($('form.license-request-form').length) {
    return $(document).on('focus', '#license_entry_attributes_debt_attributes_value', function(e) {
      var discount, due, final;
      due = $('#license_entry_attributes_entry_items_attributes_0_value').maskMoney('unmasked')[0];
      $('#license_entry_attributes_debt_attributes_due_value').val(due);
      discount = $('#license_entry_attributes_debt_attributes_discount').maskMoney('unmasked')[0];
      final = due - discount;
      return $('#license_entry_attributes_debt_attributes_value').maskMoney('mask', final, {
        'prefix': 'R$ ',
        'thousands': '.',
        'decimal': ',',
        'allowZero': true
      });
    });
  }
});
