import 'jquery-mask-plugin'
import 'javascripts/jquery.maskMoney'
import 'bootstrap-sass'
import '@nathanvda/cocoon'

import 'collector-core/app/assets/javascripts/collector/core/core'
import 'javascripts/dashboard'
import 'javascripts/activities'
import 'javascripts/banking_returning_files'
import 'javascripts/business_activities'
import 'javascripts/entries'
import 'javascripts/individual_people'
import 'javascripts/installments'
import 'javascripts/legal_people'
import 'javascripts/licenses'
import 'javascripts/license_types'
import 'javascripts/payments'
import 'javascripts/negotiations'

$(document).on('turbolinks:load', function(){

  var body = $('body');
  var dashboard = $('.dashboard');
  var mainContainer = $('.main-container');
  var mainContent = $('.main-content');
  var sidebar = $('.sidebar');
  var sidebarMenu = $('.sidebar-menu');
  var sidebarFooter = $('.sidebar-footer');
  var sidebarMenuToggle = $('#sidebar-menu-toggle');
  var navigationBar = $('.navigation-bar');
  var footer = $('footer');

  $('[data-toggle="tooltip"]').tooltip();

  function setContentHeight(){

    var windowHeight = $(window).outerHeight();
    var navigationBarHeight = navigationBar.outerHeight();
    var footerHeight = footer.outerHeight();
    var contentHeight = windowHeight - (navigationBarHeight + footerHeight) - 17;

    // normalize content
    mainContent.css('min-height', contentHeight);

  }

  function checkContentWidth(){
    var windowWidth = $(window).outerWidth();

    if (windowWidth > 1200) {
      dashboard.removeClass('sm');
      dashboard.removeClass('xs');
      dashboard.addClass('md');
    } else if (windowWidth > 768) {
      dashboard.removeClass('md');
      dashboard.removeClass('xs');
      dashboard.addClass('sm');
    } else {
      dashboard.removeClass('md');
      dashboard.removeClass('sm');
      dashboard.addClass('xs');
    }
  }

  $(window).on('resize', function(){
    setContentHeight();
    checkContentWidth();
  });

  sidebarMenu.find('a').on('click', function(ev) {
    var $li = $(this).parent();

    if ($li.is('.active')) {
      $li.removeClass('active');
      $('ul:first', $li).slideUp(function() {
          setContentHeight();
      });
    } else {
      // prevent closing menu if we are on child menu
      if (!$li.parent().is('.child-menu')) {
        sidebarMenu.find('li').removeClass('active');
        sidebarMenu.find('li ul').slideUp();
      }

      $li.addClass('active');

      $('ul:first', $li).slideDown(function() {
        setContentHeight();
      });
    }
  });

  sidebarMenuToggle.on('click', function() {
    if (dashboard.hasClass('md')) {
      sidebarMenu.find('li.active ul').hide();
    } else {
      sidebarMenu.find('li.active ul').show();
    }

    var windowWidth = $(window).outerWidth();
    if (windowWidth > 768) {
      dashboard.toggleClass('md sm');
    } else {
      dashboard.toggleClass('sm xs');
    }

    setContentHeight();

  });

  sidebarMenu.find('.child-menu > li.active').parents('ul').slideDown(0, function() {
      setContentHeight();
  });

  setContentHeight();
  checkContentWidth();

});
